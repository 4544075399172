<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="post">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div
                  class="form-inline navbar-search"
                >
                  <div class="input-group">
                    <input
                      v-on:keyup="search"
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Post Title"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div
                    :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="fetchData('all');currentPage=1"
                    class="mx-2 opt"
                  >
                    All
                  </div>
                  |
                  <div
                    :class="activeTab == 'trash' ? ' tab-options' : ''"
                    @click.prevent="fetchData('trash');currentPage=1"
                    class="mx-2 opt"
                  >
                    Trash
                  </div>
                  |
                  <div
                    :class="activeTab == 'active' ? ' tab-options' : ''"
                    @click.prevent="fetchData('active');currentPage=1"
                    class="mx-2 opt"
                  >
                    Active
                  </div>
                  <template v-if="can('add-post-an-update')">
                    |
                    <div class="d-flex align-items-center">
                      <svg
                        class="svg-inline--fa fa-table mx-2"
                        height="16px"
                        width="16px"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fa"
                        data-icon="table"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                        ></path>
                      </svg>
                      <router-link :to="{ name: 'add-post' }" class="opt"
                        >Add Post</router-link
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="post.data"
                :per-page="0"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-can="'edit-post-an-update'" v-slot:cell(edit)="row">
                  <router-link
                    :to="{
                      name: 'edit-post',
                      params: { id: row.item.id },
                      query: { action_type: row.item.action_type },
                    }"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                </template>
                <template
                  v-can="'update-case-status'"
                  v-slot:cell(is_active)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.is_active == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template
                  v-if="fields[fields.length - 1].key == 'delete'"
                  v-slot:cell(delete)="row"
                  v-can="'delete-post-an-update'"
                >
                  <div
                    v-if="activeTab != 'trash'"
                    @click.prevent="deletePost(row.item.id)"
                  >
                    <i class="mdi mdi-trash-can delete-i"></i>
                  </div>
                </template>
                <template
                  v-else
                  v-slot:cell(restore)="row"
                  v-can="'restore-post-an-update'"
                >
                  <div
                    v-if="activeTab == 'trash'"
                    @click.prevent="restorePost(row.item.id)"
                  >
                    <i class="mdi mdi-restore restore-i"></i>
                  </div>
                </template>
                <template v-slot:cell(action_type)="row">
                  <div class="d-flex justify-content-center">
                    <span
                      class="badge badge-primary"
                      style="font-size: 95% !important"
                      v-if="row.item.is_ppt == 0"
                    >
                      post an update
                    </span>
                    <span
                      v-if="row.item.is_ppt == 1"
                      class="badge badge-info"
                      style="font-size: 95% !important"
                    >
                      presentation
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
            <template v-if="post.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="post.total"
                      :total-pages="post.total_pages"
                      :per-page="post.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
            <template v-if="noData">
              <div class="no_content my-4">
                <div style="display: grid; justify-content: center">
                  <img src="../../../../public/noData.svg" alt="" />
                  <span class="no_content_text" style="text-align: center"
                    >No Post Available</span
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import PostMixin from "../../../mixins/ModuleJs/post";

export default {
  mixins: [MixinRequest, PostMixin],
  data() {
    return {
      title: "Post",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
